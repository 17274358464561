function getRandomElement<T>(arr: T[]): T {
  if (arr.length === 0) {
      throw new Error("Array must not be empty");
  }

  const randomArray = new Uint32Array(1);
  window.crypto.getRandomValues(randomArray);
  const randomIndex = randomArray[0] % arr.length;

  return arr[randomIndex];
}

  export default getRandomElement;