import React from 'react';
import './Home.css';
import logo from '../../images/smoking-pipe-no-bg.svg';
import { /* ColorProvider, */ useColorContext} from '../../context/colorContext';
import palette from '../../constants/palette';
import getRandomElement from '../../utils/getRandomElement';


function Home() {

    const {color, setColor} = useColorContext();

    const rollColor = () => {
      const newColor = getRandomElement(palette);
      setColor(newColor);
    }

    return (
      <div className="App" style={{backgroundColor:color}}>
        <header className="App-header">
          <div className='logo'>
            <div className='name'>datadada</div>
            <div className="icon-wrapper"><img onClick={rollColor} src={logo} alt="icon" className="icon" /></div>
          </div>
        </header>
        {/* <section className="section-wrapper">
            <div className="section-title">data [ˈdeɪtə]</div>
            <div className="section-content">Ubiquitous yet elusive, data needs to be treated with respect. This starts with a clear idea of what data needs to be collected and how. Valuable insights can be found in a wide array of data sources. Financial data, web analytics, social media, open government data as well as data from the pyhiscal world all tell a story. 
            <br/><br/>
            Often those stories are burried in the rough, so virtually every dataset needs a little cleaning up before being digestable.</div>
        </section>
        <section className="section-wrapper">
            <div className="section-title">dada [ˈdɑːdɑː]</div>
            <div className="section-content">Data like information in general has an infinite number of faces. Spotting the interesting angles is as much art as it is science. </div>
        </section> */}
      </div>
    );
  }
  
  export default Home;