import React from 'react';
import { Link } from 'react-router-dom';
import './NotFound.css';
import { /* ColorProvider, */ useColorContext } from '../../context/colorContext';

function Foo() {
    const {color} = useColorContext();

    return (
      <div className="Page-Not-Found" style={{backgroundColor: color}}>
        <div className="title-404">404</div>
        <div className="description-404">Page not found</div>
        <div className="action-404">go back to <Link to="/">datadada home</Link></div>
      </div>
    );
  }
  
  export default Foo;