import React, { createContext, useContext, useState, ReactNode } from 'react';
import palette from '../constants/palette';
import getRandomElement from '../utils/getRandomElement';

interface ColorContextProps {
    color: string;
    setColor: (color: string) => void;
}

const ColorContext = createContext<ColorContextProps | undefined>(undefined);

const ColorProvider = ({ children }: { children: ReactNode }) => {

    if (!sessionStorage.getItem('color')) {
        sessionStorage.setItem('color', getRandomElement(palette));
    } 

    const color = sessionStorage.getItem('color') || '#000000';
    const [currentColor, setCurrentColor] = useState<string>(color);

    const setColor = (color: string) => {
        setCurrentColor(color);
    }

    return (<ColorContext.Provider value={{ color: currentColor, setColor }}>{children}</ColorContext.Provider>);
}

const useColorContext = () => {
    const context = useContext(ColorContext);
    if (!context) {
        throw new Error('useColorContext must be used within a ColorProvider');
    }
    return context;
}

export { ColorProvider, useColorContext };